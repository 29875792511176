import { template as template_b16373e5ea9744e58dd953f586c0aebf } from "@ember/template-compiler";
export default template_b16373e5ea9744e58dd953f586c0aebf(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
