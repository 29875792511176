import { template as template_32c34af1e0d24f77a35777e9959eed0d } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { t } from 'ember-intl';
export default template_32c34af1e0d24f77a35777e9959eed0d(`
  <div class="element-flashcards-intro-card">
    {{#if @introImage}}
      <div class="element-flashcards-intro-card__image">
        <img src={{@introImage.url}} alt="" />
      </div>
    {{/if}}

    <h3 class="element-flashcards-intro-card__title">{{@title}}</h3>

    <div class="element-flashcards-intro-card__footer">
      <PixButton @triggerAction={{@onStart}} @variant="primary" @size="small">
        {{t "pages.modulix.buttons.flashcards.start"}}
      </PixButton>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
