import { template as template_5d15cf45e6fd440c87a0dd0ce8264990 } from "@ember/template-compiler";
export default template_5d15cf45e6fd440c87a0dd0ce8264990(`
  <h1>
    {{! template-lint-disable "no-bare-strings" }}
    Page Certificat V3
  </h1>
  <p>{{@certification.fullName}} {{@certification.birthdate}}</p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
