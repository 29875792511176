import { template as template_3323a084de7445d9a2e6308c57513108 } from "@ember/template-compiler";
export default template_3323a084de7445d9a2e6308c57513108(`
  <div class="campaign-step">
    <img class="campaign-step__image" role="presentation" src={{@step.image.src}} width="60" height="60" />
    <h3 class="campaign-step__title">{{@step.title}}</h3>
    <p class="campaign-step__description">{{@step.description}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
