import { template as template_ed5f0403039f4ee9b52a6cda75aea88b } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import Tabs from '../../../../tabs';
import ResultsDetails from './results-details';
import Rewards from './rewards';
import Trainings from './trainings';
export default class EvaluationResultsTabs extends Component {
    @service
    tabManager;
    get showRewardsTab() {
        const badges = this.args.campaignParticipationResult.campaignParticipationBadges;
        return badges.some((badge)=>badge.isAcquired || badge.isAlwaysVisible);
    }
    get initialTabIndex() {
        return this.showRewardsTab ? this.tabManager.setActiveTab(0) : this.tabManager.setActiveTab(1);
    }
    get showTrainingsTab() {
        return this.args.trainings.length > 0;
    }
    get showTabs() {
        return this.showRewardsTab || this.showTrainingsTab;
    }
    static{
        template_ed5f0403039f4ee9b52a6cda75aea88b(`
    {{#if this.showTabs}}
      <Tabs
        class="evaluation-results-tabs"
        @ariaLabel={{t "pages.skill-review.tabs.aria-label"}}
        @initialTabIndex={{this.initialTabIndex}}
      >
        <:tabs as |Tab|>
          {{#if this.showRewardsTab}}
            <Tab @index={{0}}>{{t "pages.skill-review.tabs.rewards.tab-label"}}</Tab>
          {{/if}}
          <Tab @index={{1}}>{{t "pages.skill-review.tabs.results-details.tab-label"}}</Tab>
          {{#if this.showTrainingsTab}}
            <Tab @index={{2}}>{{t "pages.skill-review.tabs.trainings.tab-label"}}</Tab>
          {{/if}}
        </:tabs>

        <:panels as |Panel|>
          {{#if this.showRewardsTab}}
            <Panel @index={{0}}>
              <Rewards @badges={{@campaignParticipationResult.campaignParticipationBadges}} />
            </Panel>
          {{/if}}
          <Panel @index={{1}}>
            <ResultsDetails
              @competenceResults={{@campaignParticipationResult.competenceResults}}
              @totalStage={{@campaignParticipationResult.reachedStage.totalStage}}
            />
          </Panel>
          {{#if this.showTrainingsTab}}
            <Panel @index={{2}}>
              <Trainings
                @trainings={{@trainings}}
                @isParticipationShared={{@campaignParticipationResult.isShared}}
                @isSharableCampaign={{@isSharableCampaign}}
                @questResults={{@questResults}}
                @campaignParticipationResult={{@campaignParticipationResult}}
                @campaignId={{@campaign.id}}
                @onResultsShared={{@onResultsShared}}
              />
            </Panel>
          {{/if}}
        </:panels>
      </Tabs>
    {{else}}
      <section class="evaluation-results-tabs">
        <ResultsDetails
          @competenceResults={{@campaignParticipationResult.competenceResults}}
          @totalStage={{@campaignParticipationResult.reachedStage.totalStage}}
        />
      </section>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
